import Vue from 'vue';

class OnboardingService {

    verifyEmail(payload) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/v1/account/checkemail', payload)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(Vue.prototype.$utilService.generalError(error));
                })
        })
    }

    login(payload) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/v2/account/login', payload)
                .then(response => {
                    resolve(response);
                }).catch((error) => {
                    reject(Vue.prototype.$utilService.generalError(error));
                });
        })
    }

    register(payload) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/v1/account/create', payload)
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(Vue.prototype.$utilService.generalError(error));
                });
        })
    }

    forgotPassword(payload) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/v1/account/forgotpassword', payload)
                .then(response => {
                    resolve();
                }).catch(error => {
                    reject(Vue.prototype.$utilService.generalError(error));
                })
        })
    }

    resendEmailCode(payload) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/v1/account/resend-email-validation-code', payload)
                .then(response => {
                    resolve();
                }).catch(error => {
                    reject(Vue.prototype.$utilService.generalError(error));
                })
        })
    }

    validateEmail(payload) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/v1/account/email-validation', payload)
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(Vue.prototype.$utilService.generalError(error));
                })
        })
    }

    getConsents() {
        Vue.prototype.$utilService.loadingContainer();
        Vue.prototype.$utilService.setHttpOptions();
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.get('/v1/account/consents', Vue.prototype.$utilService.httpOptions)
                .then(response => {
                    Vue.prototype.$utilService.loadingContainer();
                    resolve(response);
                }).catch(error => {
                    Vue.prototype.$utilService.loadingContainer();
                    reject(Vue.prototype.$utilService.generalError(error));
                })
        })
    }

    linkUser(payload) {
        Vue.prototype.$utilService.setHttpOptions();
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/v1/account/link-guardian-student', payload, Vue.prototype.$utilService.httpOptions)
                .then(response => {
                    resolve();
                }).catch(error => {
                    reject(Vue.prototype.$utilService.generalError(error));
                })
        })
    }

    loginInApp(payload) {
        Vue.prototype.$utilService.setHttpOptions();
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/v1/account/redirect-to-app', payload, Vue.prototype.$utilService.httpOptions)
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(Vue.prototype.$utilService.generalError(error));
                })
        })
    }

}

export default new OnboardingService();