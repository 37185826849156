<template>
  <div id="onboarding">
    <g-header :canShowBackLink="!routesDontShowBackLink" />
    <loading-container v-if="showLoading" />
    <b-container fluid="md" class="text-center">
      <router-view />
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue';
import { bus } from '../../main.js';
import OnboardingService from '@/services/onboarding.js';
Vue.prototype.$onboardingService = OnboardingService;
import Header from '@/components/Header.vue';

export default {
  components: {
    'g-header': Header,
  },
  data: () => ({
    showLoading: false,
  }),
  computed: {
    routesDontShowBackLink() {
      return (
        this.$route.fullPath.includes('/verify-email') ||
        this.$route.fullPath == '/choose-profile' ||
        this.$route.fullPath == '/account-info'
      );
    },
  },
  created() {
    if (!this.$route.fullPath.includes('/verify-email') || !this.$route.fullPath == '/error-404') {
      //caso o usuário volte no onboarding sem ter entrado por algum fluxo
      if (!localStorage.getItem('access_token')) {
        // this.$router.push('error-404');
        location.replace(process.env.VUE_APP_ACCOUNT_LINK);
      }
    }
    
    bus.$on('loadingGlobalContainer', (data) => {
      this.showLoading = !this.showLoading;
    });
  },
  watch: {
    '$route.fullPath': function(fullPath) {
      if (!fullPath.includes('/verify-email') || !fullPath == '/error-404') {
      //caso o usuário volte no onboarding sem ter entrado por algum fluxo
      if (!localStorage.getItem('access_token')) {
        // this.$router.push('error-404');
        location.replace(process.env.VUE_APP_ACCOUNT_LINK);
      }
    }
    }
  }
};
</script>

<style lang="scss" scoped>
#onboarding {
  height: 100vh;
  .container-md {
    margin-top: 6.5rem;
    @media (max-width: 768px) {
      margin-top: 5rem;
    }
  }
}
</style>