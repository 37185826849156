<template>
  <div>
    <b-navbar variant="faded" type="light">
      <b-navbar-brand class="mb-0">
        <img src="@/assets/images/logo/logo-educacross.png" alt="logo" />
      </b-navbar-brand>
      <b-navbar-brand class="mb-0">
        <a class="text-primary text-patternOne" @click="$router.back()" v-if="canShowBackLink">
          <b-icon icon="arrow-left" class="text-primary mr-2"></b-icon>
          Voltar
        </a>
      </b-navbar-brand>
    </b-navbar>
    <div class="back-mobile">
      <a class="text-primary text-patternOne" @click="$router.back()" v-if="canShowBackLink">
        <b-icon icon="arrow-left" class="text-primary mr-1"></b-icon>
        Voltar
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canShowBackLink: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 2rem 3rem;
  border-bottom: 1px solid #c4c4c4;
  justify-content: space-between;
  .navbar-brand {
    img {
      width: 238px;
      height: 39px;
    }
  }
}

.back-mobile {
  margin-top: 1rem;
  margin-right: 1.1rem;
  float: right;
}

.navbar .navbar-brand,
.back-mobile {
  a {
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (min-width: 769px) {
  .back-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar {
    justify-content: center;
    padding: 1rem 3rem;
    .navbar-brand:last-child {
      display: none;
    }
  }
}
</style>
